<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(submit)">
      <section class="section has-text-centered">
        <h1 class="title">{{ $t('home.title') }}</h1>
        <p>{{ $t('home.introduction') }}</p>

        <ValidationProvider name="userType" rules="required" v-slot="{ errors }">
          <b-field :type="{ 'is-danger': !!errors.length }" :message="errors" class="mt-6">
            <client-only placeholder="Loading...">
              <b-radio-button
                v-if="showIndividual"
                name="userType"
                v-model="userType"
                native-value="individual"
                type="is-grey is-light is-outlined"
                class="has-text-weight-bold"
                expanded
              >
                <span>{{ $t('userType.individual') }}</span>
              </b-radio-button>
            </client-only>

            <client-only placeholder="Loading...">
              <b-radio-button
                v-if="showSoleTrader"
                name="userType"
                v-model="userType"
                native-value="soletrader"
                type="is-grey is-light is-outlined"
                class="has-text-weight-bold"
                expanded
              >
                <span>{{ $t('userType.sole_trader') }}</span>
              </b-radio-button>
            </client-only>

            <b-radio-button
              name="userType"
              v-model="userType"
              native-value="company"
              type="is-grey is-light is-outlined"
              class="has-text-weight-bold"
              expanded
            >
              {{ $t('userType.company') }}
            </b-radio-button>
          </b-field>
        </ValidationProvider>

        <client-only placeholder="Loading...">
          <div v-if="!userType" class="content is-size-7">
            {{ $t('home.userTypeDescription.noChoice') }}
          </div>

          <div v-if="userType === 'individual'" class="content is-size-7">
            {{ $t('home.userTypeDescription.individual') }}
          </div>

          <div v-if="userType === 'soletrader'" class="content is-size-7">
            {{ $t('home.userTypeDescription.soletrader') }}
          </div>

          <div v-if="userType === 'company'" class="content is-size-7">
            {{ $t('home.userTypeDescription.company') }}
          </div>
        </client-only>

        <ValidationProvider name="country" rules="required" v-slot="{ errors }">
          <b-field :type="{ 'is-danger': !!errors.length }" :message="errors">
            <div class="level is-mobile input is-medium" :class="{ 'is-danger': !!errors.length }">
              <div class="level-left">
                <div class="level-item">
                  <p>{{ $t('country') }}</p>
                </div>
                <div class="level-item has-separator"></div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-select v-model="country" class="no-border country-select" name="country">
                    <option disabled="disabled" hidden="hidden" value="">
                      {{ $t('form.select_country') }}
                    </option>
                    <option value="SE">{{ $t('countries.se') }}</option>
                    <option value="DE">{{ $t('countries.de') }}</option>
                    <option value="GB">{{ $t('countries.gb') }}</option>
                    <option value="PT">{{ $t('countries.pt') }}</option>
                  </b-select>
                </div>
              </div>
            </div>
          </b-field>
        </ValidationProvider>
        <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
          <b-field :type="{ 'is-danger': !!errors.length }" :message="errors">
            <div class="level is-mobile input is-medium" :class="{ 'is-danger': !!errors.length }">
              <div class="level-left">
                <div class="level-item">
                  <p>{{ $t('mobile_number') }}</p>
                </div>
                <div class="level-item has-separator"></div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <b-input
                    v-model="phone"
                    type="text"
                    name="phone"
                    size="is-medium"
                    class="no-border"
                    @keyup.native="onPhoneKeyUp"
                  />
                </div>
              </div>
            </div>
          </b-field>
        </ValidationProvider>
      </section>
      <section class="section has-text-centered mt-6">
        <b-button
          rounded
          tag="button"
          native-type="submit"
          type="is-link is-large is-fullwidth has-text-weight-bold"
          :loading="isSubmitted"
        >
          {{ $t('continue') }}
        </b-button>

        <p class="is-size-6">
          {{ $t('you_hereby_consent_to') }}
          <br />
          <a href="https://www.tiptapp.com/en/tos">{{ $t('terms') }}</a>
          &amp;
          <a href="https://www.tiptapp.com/en/pp">{{ $t('privacy_policy') }}</a>
        </p>
      </section>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default {
  data: () => ({
    isSubmitted: false,
  }),
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    submit: async function () {
      try {
        const token = await this.$recaptcha.execute('signup');
        this.$store.commit('updateField', { path: 'captchaToken', value: token });
        this.$store.dispatch('requestCode');
        this.isSubmitted = true;
      } catch (error) {
        console.log(error);
      }
    },
    onPhoneKeyUp: function(event) {
      try {
        const number = phoneUtil.parse(this.phone, this.country);
        const formattedNumber = phoneUtil.format(number, PNF.INTERNATIONAL);
        this.$store.commit('updateField', { path: 'phone', value: formattedNumber });
      } catch (error) {}
    }
  },
  watch: {
    country(country) {
      if (this.userType === 'soletrader' && country !== 'GB') {
        this.$store.commit('setUserType', 'individual');
      }
      if (country === 'SE') {
        this.$store.commit('setUserType', 'company');
      }
    },
  },
  computed: {
    ...mapFields(['country', 'phone', 'userType']),
    showSoleTrader() {
      return this.country === 'GB';
    },
    showIndividual() {
      return this.country !== 'SE';
    },
  },
  async fetch() {
    await this.$store.dispatch('getCountry');
  },
  fetchOnServer: false,
  head() {
    return {
      title: this.$t('title'),
    };
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
};
</script>

<style scoped>
/* Reduce level item gap as in the design */
.level.is-mobile .level-item:not(:last-child) {
  margin-right: 0.35rem;
}

/* Make the level gap same as field class */
.level:not(:last-child) {
  margin-bottom: 0.75rem;
}

@media (max-width: 372px) {
  .country-select {
    max-width: 119px;
  }
}
@media (max-width: 466px) {
  .level-right .control {
    max-width: 222px;
  }
}
@media (max-width: 428px) {
  .level-right .control {
    max-width: 119px;
  }
}
</style>