var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  },
                },
              },
              [
                _c(
                  "section",
                  { staticClass: "section has-text-centered" },
                  [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("home.title"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("home.introduction")))]),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      attrs: { name: "userType", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "mt-6",
                                    attrs: {
                                      type: { "is-danger": !!errors.length },
                                      message: errors,
                                    },
                                  },
                                  [
                                    _c(
                                      "client-only",
                                      { attrs: { placeholder: "Loading..." } },
                                      [
                                        _vm.showIndividual
                                          ? _c(
                                              "b-radio-button",
                                              {
                                                staticClass:
                                                  "has-text-weight-bold",
                                                attrs: {
                                                  name: "userType",
                                                  "native-value": "individual",
                                                  type: "is-grey is-light is-outlined",
                                                  expanded: "",
                                                },
                                                model: {
                                                  value: _vm.userType,
                                                  callback: function ($$v) {
                                                    _vm.userType = $$v
                                                  },
                                                  expression: "userType",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "userType.individual"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "client-only",
                                      { attrs: { placeholder: "Loading..." } },
                                      [
                                        _vm.showSoleTrader
                                          ? _c(
                                              "b-radio-button",
                                              {
                                                staticClass:
                                                  "has-text-weight-bold",
                                                attrs: {
                                                  name: "userType",
                                                  "native-value": "soletrader",
                                                  type: "is-grey is-light is-outlined",
                                                  expanded: "",
                                                },
                                                model: {
                                                  value: _vm.userType,
                                                  callback: function ($$v) {
                                                    _vm.userType = $$v
                                                  },
                                                  expression: "userType",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "userType.sole_trader"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-radio-button",
                                      {
                                        staticClass: "has-text-weight-bold",
                                        attrs: {
                                          name: "userType",
                                          "native-value": "company",
                                          type: "is-grey is-light is-outlined",
                                          expanded: "",
                                        },
                                        model: {
                                          value: _vm.userType,
                                          callback: function ($$v) {
                                            _vm.userType = $$v
                                          },
                                          expression: "userType",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("userType.company")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c(
                      "client-only",
                      { attrs: { placeholder: "Loading..." } },
                      [
                        !_vm.userType
                          ? _c("div", { staticClass: "content is-size-7" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("home.userTypeDescription.noChoice")
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.userType === "individual"
                          ? _c("div", { staticClass: "content is-size-7" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "home.userTypeDescription.individual"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.userType === "soletrader"
                          ? _c("div", { staticClass: "content is-size-7" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "home.userTypeDescription.soletrader"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.userType === "company"
                          ? _c("div", { staticClass: "content is-size-7" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("home.userTypeDescription.company")
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      attrs: { name: "country", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "b-field",
                                  {
                                    attrs: {
                                      type: { "is-danger": !!errors.length },
                                      message: errors,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "level is-mobile input is-medium",
                                        class: { "is-danger": !!errors.length },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "level-left" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "level-item" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("country"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass:
                                                "level-item has-separator",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "level-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "level-item" },
                                              [
                                                _c(
                                                  "b-select",
                                                  {
                                                    staticClass:
                                                      "no-border country-select",
                                                    attrs: { name: "country" },
                                                    model: {
                                                      value: _vm.country,
                                                      callback: function ($$v) {
                                                        _vm.country = $$v
                                                      },
                                                      expression: "country",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "disabled",
                                                          hidden: "hidden",
                                                          value: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "form.select_country"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "SE" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "countries.se"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "DE" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "countries.de"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "GB" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "countries.gb"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "PT" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "countries.pt"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      attrs: { name: "phone", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "b-field",
                                  {
                                    attrs: {
                                      type: { "is-danger": !!errors.length },
                                      message: errors,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "level is-mobile input is-medium",
                                        class: { "is-danger": !!errors.length },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "level-left" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "level-item" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("mobile_number")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass:
                                                "level-item has-separator",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "level-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "level-item" },
                                              [
                                                _c("b-input", {
                                                  staticClass: "no-border",
                                                  attrs: {
                                                    type: "text",
                                                    name: "phone",
                                                    size: "is-medium",
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.onPhoneKeyUp.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.phone,
                                                    callback: function ($$v) {
                                                      _vm.phone = $$v
                                                    },
                                                    expression: "phone",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "section has-text-centered mt-6" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          rounded: "",
                          tag: "button",
                          "native-type": "submit",
                          type: "is-link is-large is-fullwidth has-text-weight-bold",
                          loading: _vm.isSubmitted,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("continue")) + "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "is-size-6" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("you_hereby_consent_to")) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        { attrs: { href: "https://www.tiptapp.com/en/tos" } },
                        [_vm._v(_vm._s(_vm.$t("terms")))]
                      ),
                      _vm._v("\n        &\n        "),
                      _c(
                        "a",
                        { attrs: { href: "https://www.tiptapp.com/en/pp" } },
                        [_vm._v(_vm._s(_vm.$t("privacy_policy")))]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }